import React, { useState, useEffect } from "react";
import axios from "axios";

import loginHandlerAPI from "../../API/axios/login";

import { useFormik } from "formik";
import { useHistory } from "react-router-dom";

import { images } from "../../constants";

import styles from "./styles.module.scss";

const LogIn = () => {
  const history = useHistory();

  const token = localStorage.getItem("@token");

  const logInHandler = async (values) => {
    setIsBtnDisabled(true);
    const { result } = await loginHandlerAPI(values.login, values.password);

    if (result.data.accessToken) {
      localStorage.setItem("@token", result.data.accessToken);
      setIsBtnDisabled(false);
      history.push("Home");
    } else {
      setIsBtnDisabled(false);
      alert("Something went wrong! Try again later");
    }
  };
  const formik = useFormik({
    initialValues: {
      login: "",
      password: "",
    },
    onSubmit: (values) => logInHandler(values),
  });
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);

  useEffect(() => {
    if (formik.values.login.length && formik.values.password.length)
      setIsBtnDisabled(false);
    else setIsBtnDisabled(true);
  }, [formik.values.login.length, formik.values.password.length]);

  useEffect(() => {
    token && history.push("/Home");
  }, [token, history]);

  const aa = async () => {
    // var data = JSON.stringify({
    //   login: "admin",
    //   password: "admin",
    // });
    // var config = {
    //   method: "post",
    //   url: "https://appgoviral.org/admin/weblogin",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   data: data,
    // };

    // axios(config).then(function (response) {
    //   console.log(JSON.stringify(response.data));
    // });
    const response = await fetch("https://appgoviral.org/admin/weblogin", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        login: "admin",
        password: "admin",
      }),
    });

    const resp = await response.json();
    console.log("resp", resp);
  };

  const aa2 = async () => {
    var data = JSON.stringify({
      login: "admin",
      password: "admin",
    });
    var config = {
      method: "post",
      url: "https://appgoviral.org/admin/weblogin",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config).then(function (response) {
      console.log(JSON.stringify(response.data));
    });
  };
  return (
    <div className={styles.screenWrapper}>
      <img className={styles.bgBlur} src={images.blur_top_right} alt="blur" />

      <div className={styles.signInWrapper}>
        <div className={styles.logoContainer}>
          <img className={styles.logo} src={images.app_icon} alt="logo" />
        </div>

        <div className={styles.logInTextContainer}>
          <p>Login to your account</p>
        </div>

        <form onSubmit={formik.handleSubmit} className={styles.form} id="form">
          <div className={styles.inputContainer}>
            <p className={styles.inputHeading}>Login</p>
            <input
              name="login"
              value={formik.values.login}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={styles.input}
              placeholder="Enter login"
            />
          </div>

          <div className={styles.inputContainer}>
            <p className={styles.inputHeading}>Password</p>
            <input
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type="password"
              className={styles.input}
              placeholder="Enter password"
            />
          </div>
        </form>

        <button
          form="form"
          type="submit"
          disabled={isBtnDisabled}
          className={`${styles.button} ${
            isBtnDisabled && styles.button__disabled
          }`}
        >
          LOGIN
        </button>
      </div>
    </div>
  );
};

export default LogIn;
