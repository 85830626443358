import React from "react";
import Loader from "../Loader";

import styles from "./styles.module.scss";

const DefaultBtn = ({
  title,
  disabled = false,
  callback,
  type = "button",
  loading = false,
}) => {
  return (
    <button
      type={type}
      onClick={callback}
      disabled={disabled}
      className={`${styles.button} ${disabled && styles.button__disabled} ${
        loading && styles.button__loading
      }`}
    >
      {!loading ? title : <Loader />}
    </button>
  );
};

export default DefaultBtn;
