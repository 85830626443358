import instance from './index';
// import store from '../../redux';

const getAccessToken = async () => {
	let result = null;
	await instance
		.get('/admin/refresh')
		.then((res) => {
			result = true;
			localStorage.setItem('@token', res.data.accessToken);
		})
		.catch((e) => {
			console.log('e.response FROM REFRESH TOKEN', e.response);
			localStorage.removeItem('@token');
			window.location.pathname = '/LogIn';
			alert('Security Alert. Session was expired!');
		});
	return result;
};

export default getAccessToken;
