import React, { useState, useEffect } from "react";
import EarningChart from "../../components/earningChart";
import ProfitBlock from "../../components/profitBlock";
import UsersChart from "../../components/usersChart";
import BasicStatistics from "../../components/basicStatistics";

import styles from "./styles.module.scss";
import { GET } from "../../API/axios";

const Statistics = () => {
  const [stat, setStat] = useState();
  useEffect(() => {
    getStatistics();
  }, []);

  const getStatistics = async () => {
    const { result, error } = await GET("/admin/statistics");
    if (error) {
      alert(error.message);
    }
    if (result) {
      setStat(result.data);
    }
  };

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.leftBlock}>
        <BasicStatistics stat={stat} />
      </div>
    </div>
  );
};

export default Statistics;
