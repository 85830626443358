import React from "react";

const Document = ({ fill }) => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity={fill ? 1 : "0.4"}>
        <path
          d="M12.7162 14.2234H5.4962"
          stroke={fill ? "white" : "#1B1D21"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.7162 10.0369H5.4962"
          stroke={fill ? "white" : "#1B1D21"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.2513 5.86008H5.4963"
          stroke={fill ? "white" : "#1B1D21"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.9086 0.749786C12.9086 0.749786 5.2316 0.753786 5.2196 0.753786C2.4596 0.770786 0.750599 2.58679 0.750599 5.35679V14.5528C0.750599 17.3368 2.4726 19.1598 5.2566 19.1598C5.2566 19.1598 12.9326 19.1568 12.9456 19.1568C15.7056 19.1398 17.4156 17.3228 17.4156 14.5528V5.35679C17.4156 2.57279 15.6926 0.749786 12.9086 0.749786Z"
          stroke={fill ? "white" : "#1B1D21"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default Document;
