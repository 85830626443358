import React, { useState, useEffect } from "react";
import NavBar from "../../components/navbar";
import { Route, useHistory } from "react-router-dom";

import { GET } from "../../API/axios";

import { Statistics } from "../index";
// import { Settings } from '../settings/Settings';

import styles from "./styles.module.scss";
import UsersManagement from "../usersManagement";
import Admins from "../admins";
import BarOwners from "../barOwners";
import BarsManagement from "../barsManagement";

export const Home = () => {
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [admin, setAdmin] = useState(false);
  const {
    location: { pathname },
  } = useHistory();
  const history = useHistory();

  const getProfile = async () => {
    const result = await GET("/admin/profile");

    console.log("RESULT OF GET PROFILE", result);
    setAdmin(result.result?.data.profile.super);
  };

  // const token = localStorage.getItem('@token');

  const getTitleHandler = () => {
    switch (pathname) {
      case "/Statistics": {
        return "ZÔ Admin Platform";
      }
      case "/Usersmanagement": {
        return "ZÔ Admin Platform";
      }
      case "/Barsmanagement": {
        return "ZÔ Admin Platform";
      }
      case "/Admins": {
        return "ZÔ Admin Platform";
      }
      case "/Barowners": {
        return "ZÔ Admin Platform";
      }
      default: {
        return "";
      }
    }
  };

  useEffect(() => {
    getProfile();
    history.push("/Statistics");
  }, [history]);

  useEffect(() => {
    setTitle(getTitleHandler());
  }, [pathname]);

  // useEffect(() => {
  // 	token && history.push('/Statistics');
  // }, []);

  return (
    <div className={styles.home}>
      <NavBar setTitle={setTitle} setSubtitle={setSubtitle} admin={admin} />
      <div className={styles.main_content}>
        <div className={styles.title_container}>
          <p className={styles.main_title}>{title}</p>
          <p className={styles.main_subtitle}>
            {subtitle.length ? subtitle : "Statistics"}
          </p>
        </div>
        <div className={styles.manage_container}>
          <Route exact path="/Statistics" component={Statistics} />
          <Route exact path="/Usersmanagement" component={UsersManagement} />
          <Route exact path="/Barsmanagement" component={BarsManagement} />
          <Route exact path="/Barowners" component={BarOwners} />
          <Route exact path="/Admins" component={Admins} />
        </div>
      </div>
    </div>
  );
};

export default Home;
