import shop from "../assets/icons/shop";
import settings from "../assets/icons/settings";
import users from "../assets/icons/users";
import statistics from "../assets/icons/statistics";
import document from "../assets/icons/document";
import search from "../assets/icons/search";
const icons = {
  statistics,
  users,
  settings,
  shop,
  document,
  search,
};

export default icons;
