import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';

import styles from './styles.module.scss';

const NavButton = ({ Icon, text, setSubtitle }) => {
	const history = useHistory();

	const btnHandler = () => {
		setSubtitle(text);
	};

	return (
		<NavLink
			to={text.replace(/\s/g, '')}
			activeClassName={styles.active}
			className={styles.main_box}
			onClick={btnHandler}
		>
			<div className={styles.btn_link}>
				<Icon
					fill={
						history.location.pathname.slice(1) ===
						text.replace(/\s/g, '')
					}
				/>
				<p
					className={`${
						history.location.pathname.slice(1) ===
						text.replace(/\s/g, '')
							? styles.active_text
							: styles.text
					} `}
				>
					{text}
				</p>
			</div>
		</NavLink>
	);
};

export default NavButton;
