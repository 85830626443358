const shop = `<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.00001 11C4.49706 11 4.90001 11.403 4.90001 11.9V16.1H13.1V11.9C13.1 11.403 13.5029 11 14 11C14.4971 11 14.9 11.403 14.9 11.9V17C14.9 17.4971 14.4971 17.9 14 17.9H4.00001C3.50295 17.9 3.10001 17.4971 3.10001 17V11.9C3.10001 11.403 3.50295 11 4.00001 11Z" fill="#1B1D21"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M0 17C0 16.5029 0.402944 16.1 0.9 16.1H17.1C17.5971 16.1 18 16.5029 18 17C18 17.4971 17.5971 17.9 17.1 17.9H0.9C0.402944 17.9 0 17.4971 0 17Z" fill="#1B1D21"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M13.9 7C13.9 7.60751 14.3925 8.1 15 8.1C15.6075 8.1 16.1 7.60751 16.1 7H17.9C17.9 8.60163 16.6016 9.9 15 9.9C13.3984 9.9 12.1 8.60163 12.1 7H13.9Z" fill="#1B1D21"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M13 6.10001C13.4971 6.10001 13.9 6.50294 13.9 7C13.9 7.60751 14.3925 8.1 15 8.1C15.6075 8.1 16.1 7.60751 16.1 7C16.1 6.50294 16.5029 6.10001 17 6.10001C17.4971 6.10001 17.9 6.50294 17.9 7C17.9 8.60163 16.6016 9.9 15 9.9C13.3984 9.9 12.1 8.60163 12.1 7C12.1 6.50294 12.5029 6.10001 13 6.10001Z" fill="#1B1D21"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.00001 6.10001C9.49706 6.10001 9.90001 6.50295 9.90001 7.00001C9.90001 7.60752 10.3925 8.10001 11 8.10001C11.6075 8.10001 12.1 7.60751 12.1 7C12.1 6.50294 12.5029 6.10001 13 6.10001C13.4971 6.10001 13.9 6.50294 13.9 7C13.9 8.60163 12.6016 9.90001 11 9.90001C9.39838 9.90001 8.10001 8.60163 8.10001 7.00001C8.10001 6.50295 8.50295 6.10001 9.00001 6.10001Z" fill="#1B1D21"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.00001 6.10001C5.49706 6.10001 5.90001 6.50295 5.90001 7.00001C5.90001 7.60752 6.39249 8.10001 7.00001 8.10001C7.60752 8.10001 8.10001 7.60752 8.10001 7.00001C8.10001 6.50295 8.50295 6.10001 9.00001 6.10001C9.49706 6.10001 9.90001 6.50295 9.90001 7.00001C9.90001 8.60163 8.60163 9.90001 7.00001 9.90001C5.39838 9.90001 4.10001 8.60163 4.10001 7.00001C4.10001 6.50295 4.50295 6.10001 5.00001 6.10001Z" fill="#1B1D21"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M1.00001 6.10001C1.49706 6.10001 1.90001 6.50295 1.90001 7.00001C1.90001 7.60752 2.39249 8.10001 3.00001 8.10001C3.60752 8.10001 4.10001 7.60752 4.10001 7.00001C4.10001 6.50295 4.50295 6.10001 5.00001 6.10001C5.49706 6.10001 5.90001 6.50295 5.90001 7.00001C5.90001 8.60163 4.60163 9.90001 3.00001 9.90001C1.39838 9.90001 0.100006 8.60163 0.100006 7.00001C0.100006 6.50295 0.50295 6.10001 1.00001 6.10001Z" fill="#1B1D21"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.70315 0.0999258C5.739 0.0999629 5.77522 0.1 5.81182 0.1H12.1882C12.2248 0.1 12.261 0.0999629 12.2969 0.0999258C12.85 0.0993536 13.3159 0.0988715 13.7498 0.237449C14.1307 0.359083 14.4823 0.558162 14.7826 0.822182C15.1247 1.12298 15.364 1.52279 15.6481 1.99735C15.6665 2.02811 15.6851 2.05919 15.7039 2.09057L17.3157 4.77692C17.3249 4.79224 17.334 4.80739 17.343 4.82241C17.4863 5.06085 17.6073 5.26226 17.695 5.4829C17.7722 5.67727 17.8282 5.87944 17.862 6.08586C17.9003 6.32017 17.9002 6.55512 17.9 6.83327C17.9 6.85079 17.9 6.86848 17.9 6.88635L17.9 7H16.1L16.1 6.88635C16.1 6.52816 16.0973 6.44805 16.0856 6.37641C16.0728 6.29811 16.0515 6.22143 16.0222 6.1477C15.9954 6.08024 15.9565 6.01015 15.7722 5.70301L14.1604 3.01666C13.7899 2.39913 13.6977 2.26515 13.594 2.17393C13.4801 2.07379 13.3467 1.99827 13.2023 1.95214C13.0707 1.91011 12.9084 1.9 12.1882 1.9H5.81182C5.09166 1.9 4.92935 1.91011 4.79775 1.95214C4.65328 1.99827 4.51991 2.07379 4.40602 2.17393C4.30228 2.26515 4.2101 2.39913 3.83958 3.01666L2.22778 5.70301C2.04349 6.01015 2.00459 6.08024 1.97778 6.1477C1.94848 6.22143 1.92724 6.29811 1.91443 6.37641C1.90272 6.44805 1.90001 6.52816 1.90001 6.88635L1.90001 7.00001H0.100006L0.100009 6.88635C0.100009 6.86848 0.0999996 6.85079 0.0999901 6.83328C0.0998407 6.55513 0.0997144 6.32017 0.138039 6.08586C0.171801 5.87944 0.227798 5.67727 0.30505 5.4829C0.392739 5.26226 0.51373 5.06085 0.656966 4.82242C0.665986 4.8074 0.675094 4.79224 0.684288 4.77692L2.2961 2.09057C2.31493 2.05919 2.33353 2.02811 2.35194 1.99735C2.63601 1.52278 2.87533 1.12298 3.21742 0.822182C3.51768 0.558162 3.86929 0.359083 4.25017 0.237449C4.68411 0.0988716 5.15006 0.0993536 5.70315 0.0999258Z" fill="#1B1D21"/>
</svg>`;

export default shop;
