//---------------------Basic imports-----------------------
import React from "react";
import "./UsersCard.scss";
import moment from "moment";
//--------------------Assets----------------------------

import { useState } from "react";
import BanBtn from "../banBtn";

const UsersCard = ({ item }) => {
  //---------------------Hooks-----------------------

  const [state, setState] = React.useState({
    loading: false,
    showReviews: false,
  });
  const [ban, setBan] = React.useState(false);
  //--------------------State-----------------------

  const { showReviews } = state;

  //---------------------Handlers--------------------

  const deleteClickHandler = () => {};

  const reviewsClickHandler = () =>
    setState((prev) => ({ ...prev, showReviews: !prev.showReviews }));

  const banClickHandler = () =>
    setState((prev) => ({ ...prev, ban: !prev.ban }));
  //---------------------Layout-----------------------
  console.log("UserItem", item);
  return (
    <>
      <li className="usersCard">
        <div>
          {item?.firstName} {item?.lastName}
        </div>
        <div>{item?.userName}</div>
        <div>{item.phone}</div>
        <div>{item?.email}</div>
        <div> {moment(new Date(item?.createdAt)).format("DD/MM/YY")}</div>
      </li>
    </>
  );
};

export default UsersCard;
