import * as yup from 'yup';

import { name } from '../regexps';

const registerOwnerSchema = yup.object({
	login: yup.string().matches(name).min(4).max(30).required(),
	password: yup.string().min(8).required(),
	confirmPassword: yup.string().min(8).required(),
});

export default registerOwnerSchema;
