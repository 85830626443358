import React, { useState, useEffect } from "react";
import { GET } from "../../API/axios";
import styles from "./styles.module.scss";
import SearchInput from "../../components/SearchInput";
import Loader from "../../components/Loader";
import UsersCard from "../../components/UsersCard";
// import UsersCard from "../../components/UsersCard";
const UsersManagement = () => {
  const [users, setUsers] = useState();
  const [loadingC, setLoadingC] = useState(false);
  const [lim, setLim] = useState(10);
  const getUsers = async () => {
    setLoading(true);
    const { result, error } = await GET(`/admin/users?page=1&limit=${lim}`);
    if (error) {
      alert(error.message);
    }
    if (result) {
      console.log("RESULT USERS", result);
      setUsers(result.data.users);
      setLoading(false);
    }
    setLoading(false);
  };
  useEffect(() => {
    getUsers();
  }, [lim]);
  //-----------Hooks----------------------------

  //-----------Data----------------------------

  const infoTabs = ["Name", "UserName", "Phone", "Email", "Registration date"];

  //-----------Handlers----------------------------

  //-----------Layout------------------------------
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  console.log("search", search);
  return (
    <div className={styles.users}>
      {/* <div className={styles.users__header}>
        <SearchInput
          placeholder="Search in clients"
          onChange={setSearch}
          value={search}
        />
      </div> */}

      <div className={styles.users__infoBar}>
        {infoTabs.map((item, index) => (
          <p key={index}>{item}</p>
        ))}
      </div>

      <div className={styles.users__content}>
        <ul>
          {users?.map((item, index) => (
            <UsersCard key={index} item={item} />
          ))}
        </ul>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 200,
            }}
          >
            <Loader />
          </div>
        ) : (
          <div>
            <button
              className={styles.barsButton}
              onClick={() => setLim(lim + 10)}
            >
              Load more
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default UsersManagement;
