const users = ({ fill }) => {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 24 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 9.5C20.6884 9.5 23.9 10.8092 23.9 13.7571C23.9 15.3112 23.2563 16 21.9412 16L16.3453 16C15.8021 16 15.4183 15.449 15.5256 14.9165C15.6009 14.5429 15.9145 14.2 16.2956 14.2001C18.5549 14.2004 22.078 14.202 22.078 14.202C22.078 14.202 22.1 13.9052 22.1 13.7571C22.1 12.2449 19.7821 11.3 17 11.3C16.5279 11.3 16.0692 11.3272 15.6329 11.3798C15.2977 11.4202 14.9732 11.2608 14.7833 10.9816C14.4323 10.4658 14.6172 9.68556 15.2361 9.60709C15.8031 9.53521 16.3951 9.5 17 9.5ZM6.99998 9.5C10.6884 9.5 13.9 10.8092 13.9 13.7571C13.9 15.3112 13.2563 16 11.9412 16H2.0588C0.743685 16 0.0999756 15.3112 0.0999756 13.7571C0.0999756 10.8092 3.31153 9.5 6.99998 9.5ZM6.99998 11.3C4.21784 11.3 1.89998 12.2449 1.89998 13.7571C1.89998 13.9059 1.91998 14.202 1.91998 14.202H12.078C12.078 14.202 12.1 13.9052 12.1 13.7571C12.1 12.2449 9.78211 11.3 6.99998 11.3ZM17 0.200012C19.2927 0.200012 21.15 2.05733 21.15 4.35001C21.15 6.64269 19.2927 8.50001 17 8.50001C14.7073 8.50001 12.85 6.64269 12.85 4.35001C12.85 2.05733 14.7073 0.200012 17 0.200012ZM6.99998 0.200012C9.29266 0.200012 11.15 2.05733 11.15 4.35001C11.15 6.64269 9.29266 8.50001 6.99998 8.50001C4.70729 8.50001 2.84998 6.64269 2.84998 4.35001C2.84998 2.05733 4.70729 0.200012 6.99998 0.200012ZM17 2.00001C15.7014 2.00001 14.65 3.05144 14.65 4.35001C14.65 5.64858 15.7014 6.70001 17 6.70001C18.2985 6.70001 19.35 5.64858 19.35 4.35001C19.35 3.05144 18.2985 2.00001 17 2.00001ZM6.99998 2.00001C5.70141 2.00001 4.64998 3.05144 4.64998 4.35001C4.64998 5.64858 5.70141 6.70001 6.99998 6.70001C8.29854 6.70001 9.34998 5.64858 9.34998 4.35001C9.34998 3.05144 8.29854 2.00001 6.99998 2.00001Z"
        fill={fill ? "white" : "gray"}
      />
    </svg>
  );
};

export default users;
