//---------------------Basic imports-----------------------
import React from "react";
import "./UsersCard.scss";
import moment from "moment";
//--------------------Assets----------------------------

import { useState } from "react";

const BarsCard = ({ item }) => {
  //---------------------Hooks-----------------------

  const [state, setState] = React.useState({
    loading: false,
    showReviews: false,
  });
  const [ban, setBan] = React.useState(false);
  //--------------------State-----------------------

  const { showReviews } = state;

  //---------------------Handlers--------------------

  const deleteClickHandler = () => {};

  const reviewsClickHandler = () =>
    setState((prev) => ({ ...prev, showReviews: !prev.showReviews }));

  const banClickHandler = () =>
    setState((prev) => ({ ...prev, ban: !prev.ban }));
  //---------------------Layout-----------------------
  console.log("UserItem", item);
  return (
    <>
      <li className="barsCard">
        <div className="barsCardMain">
          <img className="barCardImages" src={item?.mainPhoto} alt="blur" />
          <span
            style={{ width: "100%", display: "flex", flexDirection: "column" }}
          >
            <span className="barCardName">{item?.name}</span>

            <span className="barCardAdress">{item?.address}</span>
          </span>
        </div>
        <div>{item?.errors.length < 1 ? "Paymant true" : "Payment false"}</div>
        <div>{item?.admin?.userName}</div>
        <div>${item?.earnings}</div>
        <div> {moment(new Date(item?.createdAt)).format("DD/MM/YY")}</div>
        <div>{item?.rating}</div>
      </li>
    </>
  );
};

export default BarsCard;
