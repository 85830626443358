//---------------------Basic imports-----------------------
import React from "react";

import moment from "moment";
import styles from "./styles.module.scss";
//--------------------Assets----------------------------

import { useState } from "react";
import BanBtn from "../banBtn";
import BanModal from "../BanModal";
import { DELETE } from "../../API/axios";

const AdminAcc = ({ item, success, setSuccess }) => {
  //---------------------Hooks-----------------------

  const [ban, setBan] = React.useState(false);
  const [deleteV, setDeleteV] = React.useState(false);
  //--------------------State-----------------------
  const deleteAdmin = async () => {
    const { result, error } = await DELETE(`/admin/?id=${item._id}`);
    console.log("ADMINS", result);
    if (error) {
      alert(error.message);
    }
    if (result) {
      console.log("RESULT DELETE", result);
      setDeleteV(false);
      setSuccess((prev) => !prev);
    }
  };
  //---------------------Handlers--------------------

  return (
    <>
      <div className={styles.ownCase}>
        <div className={styles.ownPlace}>{item.login}</div>
        <div className={styles.ownPlaceBtnCase}>
          <button
            className={styles.ownPlaceBtn}
            onClick={() => setDeleteV(true)}
          >
            Delete
          </button>
        </div>
      </div>
      {deleteV ? (
        <div className={styles.deleteModal}>
          <div className={styles.deleteTitle}>
            You want to delete this admin?
          </div>
          <div className={styles.deleteBtns}>
            <button
              className={styles.ownPlaceBtn2}
              onClick={() => setDeleteV(false)}
            >
              Cancel
            </button>
            <button
              className={styles.ownPlaceBtn3}
              onClick={() => deleteAdmin()}
            >
              Delete
            </button>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default AdminAcc;
