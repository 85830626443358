import React, { useEffect } from "react";
import { Switch, Route, useHistory } from "react-router-dom";

import { LogIn, Home } from "./pages";

const App = () => {
  const history = useHistory();

  const token = localStorage.getItem("@token");

  useEffect(() => {
    if (window.location.pathname === "/landing") {
      history.push({ pathname: "/landing" });
    } else if (token) {
      history.push({ pathname: "/Home" });
    } else {
      history.push({ pathname: "/LogIn" });
    }
  }, [history, token]);

  return (
    <Switch>
      <Route exact path="/LogIn" component={LogIn} />

      <Route
        exact
        path={[
          "/",
          "/Statistics",
          "/Usersmanagement",
          "/BarsManagement",
          "/Admins",
          "/Home",
          "/Barowners",
        ]}
        component={Home}
      />
    </Switch>
  );
};

export default App;
