import React, { useEffect } from "react";

import logo from "../../assets/images/app_icon.png";
import NavButton from "../navbutton";
import toolsArr from "./toolsArr";
import toolsArr2 from "./toolsArr2";
import { useHistory, Link } from "react-router-dom";

import styles from "./styles.module.scss";

export const NavBar = ({ setSubtitle, setTitle, admin }) => {
  const history = useHistory();
  console.log("ADMIN", admin);
  const logOutHandler = () => {
    localStorage.removeItem("@token");
    // history.push('signIn');
  };

  return (
    <div className={styles.left_navigation}>
      <div className={styles.logo_box}>
        <img
          width={"42px"}
          height={"42px"}
          src={logo}
          alt="logo"
          className={styles.logo}
        />
      </div>
      <div className={styles.main_nav_container}>
        <p className={styles.title}>Admin tools</p>
        <nav className={styles.nav_container}>
          {admin
            ? toolsArr.map((el, index) => (
                <NavButton
                  Icon={el.icon}
                  text={el.text}
                  key={index}
                  setSubtitle={setSubtitle}
                />
              ))
            : toolsArr2.map((el, index) => (
                <NavButton
                  Icon={el.icon}
                  text={el.text}
                  key={index}
                  setSubtitle={setSubtitle}
                />
              ))}
        </nav>
        <div className={styles.logout_container}>
          <div className={styles.admin_container}>
            <p className={styles.text_admin}>Admin</p>
            <p>
              <Link
                to="LogIn"
                className={styles.logout_link}
                onClick={logOutHandler}
              >
                Log out
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
