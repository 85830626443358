import React, { useState, useEffect } from "react";

import { useFormik } from "formik";

import styles from "./styles.module.scss";
import { GET, POST } from "../../API/axios";

import DefaultBtn from "../../components/defaultBtn";

import registerOwnerSchema from "../../validation/schemas/registerOwnerSchema";
import { forError } from "../../utils";

const BarOwners = () => {
  const [isPasswordConfirmed, setIsPasswordConfirmed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  const [owners, setOwners] = useState();

  const registerUser = async () => {
    setLoading(true);
    const { result, error } = await POST("/admin/register/admin", {
      userName: formik.values.login,
      password: formik.values.password,
    });
    if (error) {
      alert(error.message);
    }
    if (result) alert("Bar owner was created successfully");
    getOwners();
    console.log("RESULT", result);
    setLoading(false);
    formik.resetForm();
  };

  const formik = useFormik({
    validationSchema: registerOwnerSchema,

    initialValues: {
      login: "",
      password: "",
      confirmPassword: "",
    },
    onSubmit: (values) => registerUser(values),
  });

  useEffect(() => {
    if (
      formik.errors.login ||
      formik.errors.password ||
      formik.values.confirmPassword !== formik.values.password ||
      !formik.values.confirmPassword.length
    )
      setIsBtnDisabled(true);
    else setIsBtnDisabled(false);
  }, [
    formik.errors.login,
    formik.errors.password,
    formik.values.confirmPassword,
    formik.values.password,
  ]);

  useEffect(() => {
    if (formik.values.password === formik.values.confirmPassword) {
      setIsPasswordConfirmed(true);
    } else setIsPasswordConfirmed(false);
  }, [formik.values.password, formik.values.confirmPassword]);
  useEffect(() => {
    getOwners();
  }, []);
  const getOwners = async () => {
    const { result, error } = await GET("/admin/moderators");
    if (error) {
      alert(error.message);
    }
    if (result) {
      setOwners(result.data.moderators);
      console.log("RESULT Owners", result);
    }
  };
  return (
    <div className={styles.globalContainerWrap}>
      <div className={styles.container}>
        <h2 className={styles.blockHeading}>Create new Bar Owner</h2>
        <form onSubmit={formik.handleSubmit} id="form" className={styles.form}>
          <div className={styles.inputWrapper}>
            <p className={styles.inputHeading}>Login</p>
            <input
              maxLength={25}
              name="login"
              value={formik.values.login}
              onChange={formik.handleChange}
              onBlur={formik.handleBlue}
              placeholder="Login"
              className={styles.input}
            />
            <p className={styles.errorText}>
              {forError(formik.values.login, formik.errors.login) &&
                "Login must be at least 4 characters"}
            </p>
          </div>
          <div className={styles.inputWrapper}>
            <p className={styles.inputHeading}>Password</p>
            <input
              type="password"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlue}
              placeholder="Password"
              className={styles.input}
            />
            <p className={styles.errorText}>
              {forError(formik.values.password, formik.errors.password) &&
                "Password must be at least 8 characters"}
            </p>
          </div>
          <div className={styles.inputWrapper}>
            <p className={styles.inputHeading}>Confirm password</p>
            <input
              type="password"
              name="confirmPassword"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlue}
              placeholder="Confirm password"
              className={styles.input}
            />
            <p className={styles.errorText}>
              {!isPasswordConfirmed &&
                formik.values.confirmPassword &&
                "Password must be confirmed"}
            </p>
          </div>
          <DefaultBtn
            type="submit"
            disabled={isBtnDisabled}
            title="Create bar owner"
          />
        </form>
      </div>
      <div className={styles.containerOwners}>
        <h2 className={styles.blockHeading}>Bar Owners</h2>
        <div className={styles.ownLogin}>Login</div>
        {owners?.map((item) => (
          <div className={styles.ownCase}>
            <div className={styles.ownPlace}>{item.userName}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BarOwners;
