import instance from '../index';

const loginHandler = async (login, password) => {
	let result = null;
	let error = null;

	await instance
		.post('/admin/login', {
			login,
			password,
		})
		.then((res) => {
			result = res;
		})
		.catch((e) => {
			console.log(e.response);
			alert(e.response.data.message);
		});

	return { result, error };
};

export default loginHandler;
