import icons from "../../constants/icons";

const toolsArr2 = [
  {
    icon: icons.statistics,
    text: "Statistics",
  },
  {
    icon: icons.users,
    text: "Users management",
  },
  {
    icon: icons.users,
    text: "Bars management",
  },
];

export default toolsArr2;
