import instance from "../index";
import getAccessToken from "../getAccessToken";

export const DELETE = async (query, body = {}) => {
  const token = localStorage.getItem("@token");
  let result = null;
  let error = null;

  await instance
    .delete(query, {
      data: body,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      result = res;
      return res;
    })
    .catch((e) => {
      error = e;
      console.log("error", error.response);
    });

  return { result, error: error ? error.response : error };
};

export const GET = async (query) => {
  const token = localStorage.getItem("@token");
  let result = null;
  let error = null;

  console.log(token);

  await instance
    .get(query, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      result = res;
    })
    .catch((e) => {
      if (e.response?.status === 401) {
        const getNewToken = async () => {
          await getAccessToken().catch((e) =>
            console.log("ERROR ACCESS", e.response)
          );

          await GET(query);
        };
        getNewToken();
      }
      error = e;
      console.log(error.response);
    });

  return { result, error: error ? error.response : error };
};

export const POST = async (query, body) => {
  const token = localStorage.getItem("@token");
  let result = null;
  let error = null;

  await instance
    .post(query, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      result = res;
      return res;
    })
    .catch((e) => {
      if (e.response?.status === 401) {
        getAccessToken()
          .then((res) => console.log(res))
          .catch((e) => console.log(e));
        POST(query, body);
      }
      error = e.response;
      console.log(error.response);
    });

  return { result, error: error ? error.data : error };
};

export const PUT = async (query, body) => {
  const token = localStorage.getItem("@token");
  let result = null;
  let error = null;

  await instance
    .put(query, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      result = res;
      return res;
    })
    .catch((e) => {
      if (e.response?.status === 401) {
        getAccessToken()
          .then((res) => console.log(res))
          .catch((e) => console.log(e));
      }
      PUT(query, body);
      error = e;
      console.log(error.response);
    });

  return { result, error: error ? error.response : error };
};
