import icons from "../../constants/icons";

const toolsArr = [
  {
    icon: icons.statistics,
    text: "Statistics",
  },
  {
    icon: icons.users,
    text: "Users management",
  },
  {
    icon: icons.users,
    text: "Bars management",
  },
  {
    icon: icons.settings,
    text: "Admins",
  },
  {
    icon: icons.document,
    text: "Bar owners",
  },
];

export default toolsArr;
