import React, { useState, useEffect } from "react";
import { GET } from "../../API/axios";
import styles from "./styles.module.scss";
import SearchInput from "../../components/SearchInput";
import Loader from "../../components/Loader";
import UsersCard from "../../components/UsersCard";
import BarsCard from "../../components/BarsCard";
const BarsManagement = () => {
  const [places, setPlaces] = useState();
  const [lim, setLim] = useState(5);
  const getPlaces = async () => {
    setLoading(true);
    const { result, error } = await GET(`/admin/places?page=1&limit=${lim}`);
    if (error) {
      alert(error.message);
    }
    if (result) {
      console.log("RESULT Places", result);
      setPlaces(result.data.places);
      setLoading(false);
    }
  };
  useEffect(() => {
    getPlaces();
  }, [lim]);
  //-----------Hooks----------------------------

  //-----------Data----------------------------

  const infoTabs = [
    "Name",
    "Payment Status",
    "Admin",
    "Sales",
    "Created at",
    "Rating",
  ];

  //-----------Handlers----------------------------

  //-----------Layout------------------------------
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  console.log("search", search);
  return (
    <div className={styles.users}>
      {/* <div className={styles.users__header}>
        <SearchInput
          placeholder="Search in Bars"
          onChange={setSearch}
          value={search}
        />
      </div> */}

      <div className={styles.users__infoBar}>
        {infoTabs.map((item, index) => (
          <p key={index}>{item}</p>
        ))}
      </div>

      <div className={styles.users__content}>
        <ul>
          {places?.map((item, index) => (
            <BarsCard key={index} item={item} />
          ))}
        </ul>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 200,
            }}
          >
            <Loader />
          </div>
        ) : (
          <div>
            <button
              className={styles.barsButton}
              onClick={() => setLim(lim + 5)}
            >
              Load more
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default BarsManagement;
