import React from 'react';

const Statistics = ({ fill }) => {
	return (
		<svg
			width="18"
			height="15"
			viewBox="0 0 18 15"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M16.7143 13C17.1877 13 17.5714 13.3838 17.5714 13.8572C17.5714 14.3306 17.1877 14.7143 16.7143 14.7143H1.28573C0.812345 14.7143 0.428589 14.3306 0.428589 13.8572C0.428589 13.3838 0.812345 13 1.28573 13H16.7143ZM15.8572 0.142883C16.3305 0.142883 16.7143 0.526639 16.7143 1.00003V5.28574C16.7143 5.75913 16.3305 6.14288 15.8572 6.14288C15.3838 6.14288 15 5.75913 15 5.28574V3.06917L9.60611 8.46326C9.29712 8.77225 8.81092 8.79602 8.47467 8.53457L8.39393 8.46326L6.42859 6.49792L2.74897 10.1775C2.41423 10.5123 1.87152 10.5123 1.53678 10.1775C1.20205 9.84281 1.20205 9.3001 1.53678 8.96536L5.8225 4.67965C6.15723 4.34491 6.69994 4.34491 7.03468 4.67965L9.00002 6.64499L13.7863 1.85717H11.5714C11.1319 1.85717 10.7696 1.52628 10.7201 1.09999L10.7143 1.00003C10.7143 0.526639 11.0981 0.142883 11.5714 0.142883H15.8572Z"
				fill={fill ? 'white' : 'gray'}
			/>
		</svg>
	);
};

export default Statistics;
