const atLeastOneNumb = new RegExp(/^(?=.*[0-9])/);
const atLeastOneCapitalLetter = new RegExp(/^(?=.*[A-Z])/);
const atLeastOneLowerCaseLetter = new RegExp(/^(?=.*[a-z])/);
const name = new RegExp(/^(?=.*[A-Za-z0-9]$)[A-Za-z][A-Za-z\d.-]{0,19}$/);
const nickname = new RegExp(/^[a-z]([_](?![_])|[a-z]){3,18}[a-z]$/);

export {
	atLeastOneNumb,
	atLeastOneCapitalLetter,
	atLeastOneLowerCaseLetter,
	name,
	nickname,
};
