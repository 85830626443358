import React from "react";

import userSVG from "../../assets/icons/user.svg";
import drinkSVG from "../../assets/icons/drink.svg";
import bagSVG from "../../assets/icons/bag.svg";
import styles from "./styles.module.scss";
import Loader from "../Loader";

const BasicStatistics = ({ stat }) => {
  console.log("RESULT Statistics", stat);
  return (
    <div className={styles.container}>
      <h2 className={styles.heading}>Total amount 💡</h2>
      <div className={styles.statisticsContainer}>
        <div
          className={styles.statisticBlock}
          style={{
            borderTopLeftRadius: 16,
            borderBottomLeftRadius: 16,
          }}
        >
          <div className={styles.headingWrapper}>
            <img src={userSVG} alt="" />
            <p className={styles.title}>Users</p>
          </div>
          <h3 className={styles.number}>
            {stat ? stat.usersAmount : <Loader />}
          </h3>
        </div>
        <div className={styles.statisticBlock}>
          <div className={styles.headingWrapper}>
            <img src={drinkSVG} alt="" />
            <p className={styles.title}>Bars</p>
          </div>
          <h3
            className={styles.number}
            style={{ borderBottomColor: "#FA36F8" }}
          >
            {stat ? stat.placesAmount : <Loader />}
          </h3>
        </div>
        <div
          className={styles.statisticBlock}
          style={{
            borderTopRightRadius: 16,
            borderBottomRightRadius: 16,
          }}
        >
          <div className={styles.headingWrapper}>
            <img src={bagSVG} alt="" />
            <p className={styles.title}>Orders</p>
          </div>
          <h3
            className={styles.number}
            style={{
              borderBottomColor: "#450FD9",
            }}
          >
            {stat ? stat?.ordersAmount : <Loader />}
          </h3>
        </div>
      </div>
    </div>
  );
};

export default BasicStatistics;
